// require('./bootstrap');
require('jquery-validation');
require('jquery-validation/dist/additional-methods');
require('jquery-validation/dist/localization/messages_pt_BR');
require('jquery-mask-plugin');

jQuery.validator.addMethod("cnpj", function (value, element) {

    var numeros, digitos, soma, i, resultado, pos, tamanho, digitos_iguais;
    if (value.length == 0) {
        return false;
    }

    value = value.replace(/\D+/g, '');
    digitos_iguais = 1;

    for (i = 0; i < value.length - 1; i++)
        if (value.charAt(i) != value.charAt(i + 1)) {
            digitos_iguais = 0;
            break;
        }
    if (digitos_iguais)
        return false;

    tamanho = value.length - 2;
    numeros = value.substring(0, tamanho);
    digitos = value.substring(tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0)) {
        return false;
    }
    tamanho = tamanho + 1;
    numeros = value.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }

    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

    return (resultado == digitos.charAt(1));
});

$(document).ready(function () {
    $('form#form-login').validate({
        rules: {
            cnpj: {
                required: true,
                cnpj: true
            },
            token: {
                required: true
            }
        },
        messages: {
            cnpj: "CNPJ inválido"
        }
    });

    $('form#form-upload').validate({
        rules: {
            file: {
                required: true,
            },
        }
    });

    $('input.cnpj').mask('00.000.000/0000-00', {reverse: true});
});
